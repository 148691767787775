import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';

@Component({
  selector: 'app-status-filter',
  standalone: true,
  imports: [NgClass],
  templateUrl: './status-filter.component.html',
  styleUrl: './status-filter.component.scss',
})
export class StatusFilterComponent {
  availableStatus = input<number[]>();

  removeAll = output();
  filterStatus = output<number>();
  activefilter = input<any[]>([]);
  hideReset = input<boolean>(false);
  alignRight = input<boolean>(false);

  isAvailable(value: number) {
    return this.availableStatus()?.includes(value);
  }

  isActive(item: number) {
    return this.activefilter().some((e) => e === item);
  }
}
