import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textShorter',
  standalone: true,
})
export class TextShorterPipe implements PipeTransform {
  transform(value: string, maxLength: number = 25): string {
    if (typeof value !== 'string') {
      return value;
    }

    if (value.length > maxLength) {
      return value.substring(0, maxLength) + '...';
    }

    return value;
  }
}
