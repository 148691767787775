<div class="flex w-full">
  <div
    class="w-full flex flex-wrap gap-2 justify-between"
    [class.justify-end]="alignRight()"
  >
    <button
      [disabled]="!isAvailable(0)"
      [class.btn-disabled]="!isAvailable(0)"
      class="btn btn-outline border-s0 hover:bg-s0 hover:border-s0 group"
      (click)="filterStatus.emit(0)"
      [ngClass]="{
        'bg-white': !isActive(0),
        'bg-s0': isActive(0),
      }"
    >
      <svg
        class="size-6 group-hover:fill-s0 stroke-s0"
        [ngClass]="{
          'fill-white': !isActive(0),
          'fill-s0': isActive(0),
          'stroke-white': isActive(0),
        }"
        xmlns="http://www.w3.org/2000/svg"
        width="512"
        height="512"
        viewBox="0 0 512 512"
      >
        <path
          d="M112,320c0-93,124-165,96-272,66,0,192,96,192,272a144,144,0,0,1-288,0Z"
          class="group-hover:stroke-white"
          [class.stroke-white]="isActive(0)"
          style="
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 32px;
          "
        />
        <path
          d="M320,368c0,57.71-32,80-64,80s-64-22.29-64-80,40-86,32-128C266,240,320,310.29,320,368Z"
          class="group-hover:stroke-white"
          [class.stroke-white]="isActive(0)"
          style="
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 32px;
          "
        />
      </svg></button
    ><button
      [disabled]="!isAvailable(1)"
      [class.btn-disabled]="!isAvailable(1)"
      (click)="filterStatus.emit(1)"
      [ngClass]="{
        'bg-white': !isActive(1),

        'bg-s1': isActive(1),
      }"
      class="btn btn-outline border-s1 hover:bg-s1 group hover:border-s1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="size-6 fill-s1 group-hover:fill-white"
        [class.fill-white]="isActive(1)"
        [class.fill-s1]="!isActive(1)"
      >
        <path
          fill-rule="evenodd"
          d="M.75 9.75a3 3 0 0 1 3-3h15a3 3 0 0 1 3 3v.038c.856.173 1.5.93 1.5 1.837v2.25c0 .907-.644 1.664-1.5 1.838v.037a3 3 0 0 1-3 3h-15a3 3 0 0 1-3-3v-6Zm19.5 0a1.5 1.5 0 0 0-1.5-1.5h-15a1.5 1.5 0 0 0-1.5 1.5v6a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-6Z"
          clip-rule="evenodd"
        />
      </svg></button
    ><button
      class="btn btn-outline border-s2 hover:bg-s2 hover:border-s2 group"
      (click)="filterStatus.emit(2)"
      [disabled]="!isAvailable(2)"
      [ngClass]="{
        'bg-white': !isActive(2),

        'bg-s2': isActive(2),
      }"
      [class.btn-disabled]="!isAvailable(2)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="stroke-s2"
        class="size-6 fill-s2 group-hover:fill-white"
        [class.fill-white]="isActive(2)"
      >
        <path
          d="M4.5 9.75a.75.75 0 0 0-.75.75V15c0 .414.336.75.75.75h6.75A.75.75 0 0 0 12 15v-4.5a.75.75 0 0 0-.75-.75H4.5Z"
        />
        <path
          fill-rule="evenodd"
          d="M3.75 6.75a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-.037c.856-.174 1.5-.93 1.5-1.838v-2.25c0-.907-.644-1.664-1.5-1.837V9.75a3 3 0 0 0-3-3h-15Zm15 1.5a1.5 1.5 0 0 1 1.5 1.5v6a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-6a1.5 1.5 0 0 1 1.5-1.5h15Z"
          clip-rule="evenodd"
        />
      </svg></button
    ><button
      class="btn btn-outline border-s3 hover:bg-s3 hover:border-s3 bg-white group"
      (click)="filterStatus.emit(3)"
      [disabled]="!isAvailable(3)"
      [ngClass]="{
        'bg-white': !isActive(3),
        'bg-s3': isActive(3),
      }"
      [class.btn-disabled]="!isAvailable(3)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="size-6 fill-s3 group-hover:fill-white"
        [class.fill-white]="isActive(3)"
      >
        <path
          fill-rule="evenodd"
          d="M3.75 6.75a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-.037c.856-.174 1.5-.93 1.5-1.838v-2.25c0-.907-.644-1.664-1.5-1.837V9.75a3 3 0 0 0-3-3h-15Zm15 1.5a1.5 1.5 0 0 1 1.5 1.5v6a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-6a1.5 1.5 0 0 1 1.5-1.5h15ZM4.5 9.75a.75.75 0 0 0-.75.75V15c0 .414.336.75.75.75H18a.75.75 0 0 0 .75-.75v-4.5a.75.75 0 0 0-.75-.75H4.5Z"
          clip-rule="evenodd"
        />
      </svg></button
    ><button
      class="btn btn-outline border-s4 hover:bg-s4 hover:border-s4 group"
      (click)="filterStatus.emit(4)"
      [disabled]="!isAvailable(4)"
      [ngClass]="{
        'bg-white': !isActive(4),

        'bg-s4': isActive(4),
      }"
      [class.btn-disabled]="!isAvailable(4)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="size-6 fill-s4 group-hover:fill-white"
        [class.fill-white]="isActive(4)"
      >
        <path
          fill-rule="evenodd"
          d="M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
    @if (!hideReset()) {
      <button class="btn w-1/8 btn-error" (click)="removeAll.emit()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="size-6"
          viewBox="0 0 512 512"
        >
          <path
            d="M320 146s24.36-12-64-12a160 160 0 10160 160"
            fill="none"
            stroke="white"
            stroke-linecap="round"
            stroke-miterlimit="10"
            stroke-width="32"
          />
          <path
            fill="none"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="32"
            d="M256 58l80 80-80 80"
          />
        </svg>
      </button>
    }
  </div>
</div>
